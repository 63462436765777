export const IMPORTING = 1;
export const SANITIZING = 2;
export const SANITIZED = 3;
export const SCHEDULED = 4;
export const CANCELLED = 5;
export const SENDING = 6;
export const SENT = 7;

export interface LeadListStatus {
  id: number;
  status: string;
}
