import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { AffiliatesComponent } from './affiliates/affiliates.component';
import { BroadcastComponent } from './broadcasts/broadcast/broadcast.component';
import { BroadcastsComponent } from './broadcasts/broadcasts.component';
import { ViewBroadcastComponent } from './broadcasts/view-broadcast/view-broadcast.component';
import { BroadcastWhatsappComponent } from './broadcasts-whatsapp/broadcast-whatsapp/broadcast-whatsapp.component';
import { BroadcastsWhatsappComponent } from './broadcasts-whatsapp/broadcasts-whatsapp.component';
import { ViewBroadcastWhatsappComponent } from './broadcasts-whatsapp/view-broadcast-whatsapp/view-broadcast-whatsapp.component';
import { CampaignComponent } from './campaigns/campaign/campaign.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HelpComponent } from './help/help.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { PostbacksComponent } from './integrations/postbacks/postbacks.component';
import { LayoutComponent } from './layout/layout.component';
import { SignUpSuccessComponent } from './layout/sign-up/sign-up-success/sign-up-success.component';
import { SignUpComponent } from './layout/sign-up/sign-up.component';
import { LeadResponsesComponent } from './lead-responses/lead-responses.component';
import { LeadPostbackComponent } from './leads/lead-postback/lead-postback.component';
import { LeadSequencesComponent } from './leads/lead-sequences/lead-sequences.component';
import { ListComponent } from './lists/list/list.component';
import { ListsComponent } from './lists/lists.component';
import { LoginComponent } from './login/login.component';
import { MessagesComponent } from './messages-report/messages/messages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PartnerDashboardComponent } from './partner-dashboard/partner-dashboard.component';
import { PartnerManagementComponent } from './partner-management/partner-management.component';
import { PartnerPaymentsComponent } from './partner-payments/partner-payments.component';
import { PasswordResetStartComponent } from './password-reset-start/password-reset-start.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ProfileComponent } from './profile/profile.component';
import { AddCreditsComponent } from './recharges/add-credits/add-credits.component';
import { RechargeComponent } from './recharges/recharge/recharge.component';
import { RechargesComponent } from './recharges/recharges.component';
import { ReportsComponent } from './reports/reports.component';
import { SupportFormComponent } from './support-form/support-form.component';
import { UserComponent } from './users/user/user.component';
import { UsersComponent } from './users/users.component';
import { FinancialComponent } from './financial/financial.component';
import { ViewPartnerPaymentsComponent } from './view-partner-payments/view-partner-payments.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsCampaignsComponent } from './analytics/campaigns/campaigns.component';
import { AnalyticsSalesComponent } from './analytics/sales/sales.component';
import { AnalyticsCampaignsDetailsComponent } from './analytics/campaigns/detail/detail.component';
import {MessagesReportComponent} from './messages-report/messages-report.component';
import {ShortUrlAnalysisComponent} from './short-url-analysis/short-url-analysis.component';
import { ProviderComponent } from './providers/providers.component';
import { ConsumptionReport } from './consumption-report/consumption-report.component';
import { BroadcastsFilterComponent } from './broadcasts-filter/broadcasts-filter.component';
import { FractionationsComponent } from './fractionations/fractionations.component';
import { FractionationComponent } from './fractionations/fractionation/fractionation.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'affiliates', component: AffiliatesComponent },
      {
        path: 'broadcasts', children: [
          { path: '', component: BroadcastsComponent },
          { path: 'new', component: BroadcastComponent },
          { path: ':id', component: BroadcastComponent },
          { path: 'view/:id', component: ViewBroadcastComponent },
        ]
      },
    
      {
        path: 'provider', children: [
          { path: '', component: ProviderComponent }
        ]

      },
      {
        path: 'broadcasts-whatsapp', children: [
          { path: '', component: BroadcastsWhatsappComponent },
          { path: 'new', component: BroadcastWhatsappComponent },
          { path: ':id', component: BroadcastWhatsappComponent },
          { path: 'view/:id', component: ViewBroadcastWhatsappComponent },
        ]
      },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'campaigns', children: [
          { path: '', component: CampaignsComponent },
          { path: 'new', component: CampaignComponent },
          { path: 'edit/:id', component: CampaignComponent },
        ]
      },
      { path: 'change-password', component: ChangePasswordComponent },

      { path: 'help', component: HelpComponent },
      {
        path: 'integrations', children: [
          { path: '', component: IntegrationsComponent },
          { path: ':id/postbacks', component: PostbacksComponent },
        ]
      },
      {
        path: 'lead', children: [
          { path: ':id/sequences', component: LeadSequencesComponent },
          { path: ':id/postback', component: LeadPostbackComponent },
        ]
      },
      { path: 'lead-responses', component: LeadResponsesComponent },
      {
        path: 'lists', children: [
          { path: '', component: ListsComponent },
          { path: 'new', component: ListComponent },
          { path: 'edit/:id', component: ListComponent },
        ]
      },
      {
        path: 'messages-report', children: [
          { path: '', component: MessagesReportComponent },
          { path: ':date', component: MessagesComponent }
        ]
      },
      {
        path: 'partner', children: [
          { path: '', component: PartnerDashboardComponent },
          { path: 'payments', component: PartnerPaymentsComponent }
        ]
      },
      {
        path: 'partner-management', children: [
          { path: '', component: PartnerManagementComponent },
          { path: ':id/payments', component: ViewPartnerPaymentsComponent }
        ]
      },
      { path: 'profile', component: ProfileComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'financial', component: FinancialComponent },

      {
        path: 'recharges', children: [
          { path: '', component: RechargesComponent },
          { path: 'new', component: RechargeComponent },
          { path: 'add', component: AddCreditsComponent },
          { path: 'add/:userId', component: AddCreditsComponent },
          { path: 'edit/:id', component: RechargeComponent },
        ]
      },
      { path: 'reports', component: ReportsComponent },
      { path: 'support', component: SupportFormComponent },
      {
        path: 'users', children: [
          { path: '', component: UsersComponent },
          { path: 'new', component: UserComponent },
          { path: 'edit/:id', component: UserComponent },
        ]
      },
      {
        path: 'short-url-analysis',
        component: ShortUrlAnalysisComponent
      },
      {
        path: 'broadcasts-filter', 
        component: BroadcastsFilterComponent,
      },
      {
        path: 'analytics', children: [
          { path: '', component: AnalyticsComponent },
          { path: 'campaigns', component: AnalyticsCampaignsComponent },
          { path: 'campaigns/:id', component: AnalyticsCampaignsDetailsComponent },
          { path: 'sales', component: AnalyticsSalesComponent },
        ]
      },
      {
        path: 'consumption-report', component: ConsumptionReport
      },
      {
        path: 'fractionations', children: [
          { path: '', component: FractionationsComponent },
          {path: ':id', component: FractionationComponent}
        ]
      },

    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'password-reset', component: PasswordResetStartComponent },
  { path: 'password-reset/:hash', component: PasswordResetComponent },
  { path: 'password-reset-success', component: PasswordResetSuccessComponent },
  { path: 'sign-up', pathMatch: 'full', component: SignUpComponent },
  { path: 'sign-up/success', component: SignUpSuccessComponent },
  { path: 'sign-up/:code', component: SignUpComponent },
  {
    path: '**', component: LayoutComponent,
    children: [
      { path: '', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
