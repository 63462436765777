import { Component, OnInit } from '@angular/core';
import { Fractionation, FractionationService } from 'src/app/shared/services/fractionations.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fractionations',
  templateUrl: './fractionations.component.html',
  styleUrls: ['./fractionations.component.scss']
})
export class FractionationsComponent {

  private sortRoutes(routes: Fractionation[]): void {
    routes.sort((a, b) => {
      if (a.default === b.default) {
        if (a.monitored === b.monitored) {
          return 0;
        }
        return a.monitored ? -1 : 1;
      }
      return a.default ? -1 : 1;
    });
  }


  default: boolean = false;
  monitored: boolean = false;
  isModalOpen: boolean = false;
  filterText: string = '';
  allRoutes: Fractionation[] = [];
  previousMonitoredRoute = "";
  previousDefaultRoute = "";
  isPreviousMonitoredRouteSeen = false;
  isPreviousDefaultRouteSeen = false;
  activeRoute = false;


  constructor(
    private fractionationService: FractionationService,
    private toastService: ToastService,
    private router: Router


  ) { }

  result1: { total: number, data: Fractionation[] } = { total: 0, data: [] };
  ngOnInit() {
    this.fetchRoutes();
  }


  result = {
    total: 0,
    data: []
  };

  fetchRoutes() {
    this.fractionationService.getRoutes().subscribe({
      next: (data: Fractionation[]) => {
        this.allRoutes = data;
        console.log(this.allRoutes)
        this.sortRoutes(this.allRoutes);
        this.updateFilteredResults();
      },
      error: (error) => {
        this.toastService.error('Erro ao buscar as rotas');
      }
    });
  }




  // You can also add functions to open and close the modal if needed
  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  createConfiguration() {
    const routeName = document.getElementById('routeName') as HTMLInputElement;
    const getName = this.result.data.some((route) => route.name === routeName.value.toUpperCase());
    const getDefault = this.result.data.some((route) => route.default === true);
    const getDefaulName = this.result.data.find((route) => route.default === true);
    const getMonitored = this.result.data.some((route) => route.monitored === true);
    const getMonitoredName = this.result.data.find((route) => route.monitored === true);
  

    if (getDefault && this.default) {
      this.isPreviousDefaultRouteSeen = true;
      document.getElementById('previousDefaulRouteMessage').innerHTML = "Já existe uma rota padrão: " + getDefaulName.name;

      this.toastService.info('Já existe uma rota padrão');
    
      return;
    }

    if(getMonitored && this.monitored){
      this.isPreviousMonitoredRouteSeen = true;
      document.getElementById('previousMonitoredRouteMessage').innerHTML = "Já existe uma rota monitorada: " + getMonitoredName.name;

      this.toastService.info('Já existe uma rota monitorada');
      return;
    }

    if (getMonitored && this.monitored) {
      this.toastService.info('Já existe uma rota monitorada');
      return;
    }

    if (getName) {
      this.toastService.info('Já existe uma rota com esse nome');
      return;
    }
    
    if(this.default || this.monitored){
      this.activeRoute = true;  
    }

    console.log(this.default)
    this.fractionationService.addRoute({
      name: routeName.value.toUpperCase(),
      monitored: this.monitored,
      default: this.default,
      created_at: new Date(),
      active: this.activeRoute,
      configured: false
    }).subscribe({
      next: (data: Fractionation) => {
        this.result.data.push(data);
        this.sortRoutes(this.result.data);
        this.toastService.success('Rota criada com sucesso');
        this.monitored = false;
        this.default = false;
        routeName.value = '';
        this.activeRoute =false
        document.getElementById('previousMonitoredRouteMessage').innerHTML = "";
        document.getElementById('previousDefaulRouteMessage').innerHTML = "";
        this.redirectToEdit(data);

        //aqui irei redirecionar para a nova rota
        this.closeModal();

      },
      error: (error) => {
        this.toastService.error('Erro ao criar a rota');
        console.log(error)
      }
    });


  }

  changeGroupActive(route: Fractionation) {
    route.active = !route.active;
    this.fractionationService.changeGroupActive(route).subscribe({
      next: () => {
          this.fetchRoutes();
      },
      error: (error) => {
        this.toastService.error('Erro ao desativar a rota');
      }
    });
  }
  checkboxDefault() {
    this.default = !this.default;
    console.log(this.default)
    if (this.default) {
      this.monitored = false;  // Uncheck the other checkbox if this one is checked
    }
  }

  checkboxMonitored() {
    this.monitored = !this.monitored;
    if (this.monitored) {
      this.default = false;  // Uncheck the other checkbox if this one is checked
    }
  }


  updateFilteredResults() {
    this.result.data = this.filterText ? this.allRoutes.filter(route =>
      route.name.toLowerCase().includes(this.filterText.toLowerCase())
    ) : [...this.allRoutes];
    this.result.total = this.result.data.length;
  }

  filterRouteName() {
    this.updateFilteredResults();
  }


  redirectToEdit(route: Fractionation) {
    console.log(route.id)
    this.router.navigate([`/fractionations/${route.id}`]);
  }

}
