export const environment = {
  production: true,
  title: 'BETA',
  apiUrl: 'https://v1beta.smsfunnel.com.br/api',
  baseUrl: 'https://v1beta.smsfunnel.com.br',
  postbackUrl: 'https://v1beta.smsfunnel.com.br/integrations',
  signatureUrl: 'https://azpague.com.br/cart/JcMF7b',
  supportLink: 'https://wa.me/555195224391',
  mercadoPago: {
    publicKey: 'TEST-a40c494a-ec85-4700-8210-30d58325c2ee'
  },
  firstYear: 2021,
  CALL4U_DOCS_2: 'https://www.youtube.com/watch?v=077vrTrFKPQ',
  G_TAG_ID: 'G-75QZV342K4'
};
