<div>
  <app-page-title icon="fa-file-text-o" title="Relatório de Consumo">
    <button type="button" class="btn btn-primary" (click)="generateAndDownloadCsv()">
      <i class="fa fa-download"></i>
      Download CSV
    </button>
  </app-page-title>

  <form class="filter" [formGroup]="form" (submit)="handleFilter()">
    <div class="row">
      <div class="row">
        <div class="col-md-2">
          <mat-form-field appearance="fill" class="mat-form-field-custom">
            <mat-label>Usuário</mat-label>
            <input type="text" matInput formControlName="userSearch" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleUserSelection($event)">
              <mat-option *ngFor="let user of filteredUsers" [value]="user.id">
                {{ user.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="fill" class="mat-form-field-custom">
            <mat-label>Mês</mat-label>
            <mat-select formControlName="month">
              <mat-option *ngFor="let month of months" [value]="month.number">
                {{ month.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="fill" class="mat-form-field-custom">
            <mat-label>Ano</mat-label>
            <mat-select formControlName="year">
              <mat-option *ngFor="let year of years" [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>  
      </div>
      <div style="width: fit-content" class="d-flex justify-content-center align-items-center">
        <button class="btn btn-primary mt-4" type="submit">
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    <div class="row mt-3"></div>
  </form>

  <div *ngIf="result$ | async as result">
    <ng-container *ngIf="result.data.length > 0; else notFound">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Ano</th>
            <th>Mês</th>
            <th>Nome Usuário</th>
            <th>Email Usuário</th>
            <th>SMS Comprados</th>
            <th>SMS Enviados</th>
            <th>WhatsApp Comprados</th>
            <th>WhatsApp Enviados</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of result.data">
            <td>{{ item.year }}</td>
            <td>{{ item.month }}</td>
            <td>{{ item.user.name }}</td>
            <td>{{ item.user.email }}</td>
            <td>{{ item.sms_purchased }}</td>
            <td>{{ item.sms_sent }}</td>
            <td>{{ item.whatsapp_purchased }}</td>
            <td>{{ item.whatsapp_sent }}</td>
          </tr>
        </tbody>
      </table>
      <app-pagination [currentPage]="result.current_page" [itemsPerPage]="result.per_page" [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>
    </ng-container>
    <ng-template #notFound>
      <p class="card">Nenhum consumo foi encontrado no período informado.</p>
    </ng-template>
  </div>  
</div>
