import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Control } from 'src/app/models/Control';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../model/paginated-result';
import { ConsumptionReportFilter } from 'src/app/models/Consumption-report';

@Injectable({ providedIn: 'root' })
export class ConsumptionReportService {
  constructor(private http: HttpClient) {}  

  getConsumptionReport(page: number, perPage: number, filter: ConsumptionReportFilter): Observable<PaginatedResult<ConsumptionReportFilter[]>> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('per_page', perPage.toString())
      

      if (filter.month) {
        params = params.set('month', filter.month.toString());
      }
      if (filter.year) {
        params = params.set('year', filter.year.toString());
      }
      if (filter.user_id) {
        params = params.set('user_id', filter.user_id);
      }

    return this.http.get<PaginatedResult<ConsumptionReportFilter[]>>(`${environment.apiUrl}/consumption-report`, { params });
  }

  downloadCsv(filter: ConsumptionReportFilter): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${environment.apiUrl}/consumption-report/export-csv`, { filter }, {
      headers,
      responseType: 'blob',
    });
  }

  checkReportStatus(userId: string): Observable<{ status: string }> {
    return this.http.get<{ status: string }>(`${environment.apiUrl}/report/status?user_id=${userId}`);
  }
}
