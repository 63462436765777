import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BroadcastContact } from 'src/app/models/BroadcastContact';
import { BroadcastsService } from 'src/app/shared/services/broadcasts.service';
import { PaginatedResult } from './../../../shared/model/paginated-result';
import { CopyService } from './../../../shared/services/copy.service';
import { providerStatus } from '../../../shared/utils';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/models/User';
import { ADMINISTRATOR } from 'src/app/shared/services/user.service';
import { LeadList } from 'src/app/models/LeadList';
import { Lead } from 'src/app/models/Lead';
import { SCHEDULED, IMPORTING, CANCELLED, SENT } from 'src/app/models/LeadListStatus';
import { BROADCAST_SMS } from 'src/app/models/LeadListType';
import { NUMBER } from 'src/app/models/BroadcastType';

@Component({
  selector: 'app-view-broadcast',
  templateUrl: './view-broadcast.component.html',
  styleUrls: ['./view-broadcast.component.scss']
})
export class ViewBroadcastComponent implements OnInit, OnDestroy {
  
  page = 1;
  perPage = 8;
  broadcastId: string;
  broadcast: LeadList;
  contacts: PaginatedResult<Lead[]>;
  user: User;
  showTextarea:boolean = false;
  
  
  subscriptions: Subscription[] = [];

  get TypeNumber() {
    return this.broadcast.category_id === NUMBER
  }
  
  get isScheduled() {
    return this.broadcast.status_id === SCHEDULED;
  }

  get isImporting() {
    return this.broadcast.status_id === IMPORTING;
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  isStringContent(content: any): boolean {
    return typeof content === 'string';
  }

  isArrayContent(content: any): boolean {
    return Array.isArray(content);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private copyService: CopyService,
    private broadcastsService: BroadcastsService,
    private authService: AuthService,    
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.broadcastId = params.id;
        this.findById();
        this.listContacts();
      })
    );

    this.user = this.authService.getUser(true);

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        const page = params.page || 1;
        this.page = page;
        this.listContacts();
      })
    );    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleTextarea() {
    this.showTextarea = !this.showTextarea;
  }

  formatContent(content: any): string {
    if (this.isStringContent(content)) {
      return `Números: ${content}`;
    } else if (this.isArrayContent(content)) {
      return `Números:\n${content.join('\n')}`;
    }
    return '';
  }

  findById() {
    this.subscriptions.push(
      this.broadcastsService.findById(this.broadcastId).subscribe(
        broadcast => {
          this.broadcast = broadcast
        },
        () => this.router.navigate(['/broadcasts'])
      )
    );
  } 

  listContacts(): void {
    this.subscriptions.push(
      this.broadcastsService
        .listContacts(this.broadcastId, this.page, this.perPage)
        .subscribe(contacts => this.contacts = contacts)      
    );    
  }

  getStatus(contact: Lead): string {
    switch (contact.status_id) {
      case CANCELLED:
        return 'Cancelado'
      case SENT:
        return 'Enviado'
      default:
        'Pendente'
    }
  }

  pageChanged(page: any): void {
    this.router
      .navigate(['/broadcasts/view', this.broadcast.id], { queryParams: { page } });
  }

  copyUrl(): void {
    this.copyService.copy('url', 'URL copiada com sucesso!');
  }

  copyShortUrl(): void {
    this.copyService.copy('short_url', 'URL Curta copiada com sucesso!');
  }

  getProviderStatus(status: string = '') {
    let provider = providerStatus(status);

    return provider;
  }

}
