import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Profile } from 'src/app/models/Profile';
import { User } from 'src/app/models/User';
import { ProfileService } from '../../../shared/services/profile.service';
import { ToastService } from '../../../shared/components/toast/toast.service';
import { UserService } from '../../../shared/services/user.service';
import { InputCompareValidator } from '../../../shared/validators/input-compare.validator';
import { AGENCY_CLIENT, PARTNER_CLIENT } from '../../../shared/services/user.service';
import { ProviderAccountService } from '../../../shared/services/provider-account.service';
import { ShortenerService } from '../../../shared/services/shortener.service';
import { ProviderAccount } from '../../../models/ProviderAccount';
import { Shortener } from '../../../models/Shortener';
import { FractionationService,Fractionation } from 'src/app/shared/services/fractionations.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  title = 'Novo usuário';
  name: string;
  userId: string;
  active: boolean;
  massiveSmsEnabled: boolean;
  contentTagEnabled: boolean;
  exportLeads: boolean;
  analyticsSync: boolean;
  fakeRequest: boolean;
  generatePassword: boolean;
  pixPriority: boolean;
  operationType: string;
  creditPaymentEnabled: boolean;
  userForm: UntypedFormGroup;
  profiles$: Observable<Profile[]>;
  providerAccounts: ProviderAccount[] = [];
  shorteners$: Observable<Shortener[]>;
  subscriptions: Subscription[] = [];
  operationTypes: any[] = [];
  currentMonitoredPhone: string[];
  fractionation_group_id: string;
  fractionationGroups: Fractionation[] = [];

  get showCommission() {
    const profileId = this.userForm.get('profile_id').value;
    return profileId === AGENCY_CLIENT || profileId === PARTNER_CLIENT;
  }

  get monitoredNumbers(): FormArray {
    return this.userForm.get('monitoredNumbers') as FormArray;
  }

  get user() {
    return this.userForm.getRawValue() as User;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private providerAccountService: ProviderAccountService,
    private shortenerService: ShortenerService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private UtilService: UtilService,
    private fractionationService: FractionationService
  ) {}

  ngOnInit(): void {
    this.userForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      commision: ['',null],
      phone: ['', [Validators.required, Validators.pattern(/^\+?\d{10,15}$/)]],
      monitoredNumbers: this.fb.array([], Validators.pattern(/^\+?\d{10,15}$/)),
      commission: [''],
      profile_id: ['', [Validators.required]],
      password: ['', null],
      pix_key: ['', null],
      pix_priority: ['', null],
      automation_provider_account_id: ['', null],
      broadcast_provider_account_id: ['', null],
      shortener_id: ['', null],
      password_confirmation: ['', null, InputCompareValidator.compare('password')],
      administrator: [false],
      analytics_sync: [false],
      operation_type: [''],
      credit_payment_enabled: [false],
      massive_sms_enabled: [false],
      content_tag_enabled: [false],
      currentMonitoredPhone : [''],
      fractionation_group_id:"",
      automation_fractionation_route:"",
      broadcast_fractionation_route: ""
    });

    this.getfractionationGroup();

    this.subscriptions.push(
      this.userForm.get('password').valueChanges.subscribe(value => {
        this.userForm.patchValue({ password_confirmation: '' });
      })
    );

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.userId = params['id'];
        if (this.userId) {
          this.userService.findById(this.userId).subscribe(user => {
            this.userForm.patchValue(user);
            this.active = user.active;
            this.creditPaymentEnabled = user.credit_payment_enabled;
            this.analyticsSync = user.analytics_sync;
            this.fakeRequest = user.fake_request;
            this.exportLeads = user.leads_export; 
            this.pixPriority = user.pix_priority;
            this.massiveSmsEnabled = user.massive_sms_enabled;
            this.contentTagEnabled = user.content_tag_enabled;
            this.UtilService.setExportLeads(this.userId, this.exportLeads);
                       
            // Limpar o FormArray antes de adicionar novos controles
            while (this.monitoredNumbers.length !== 0) {
              this.monitoredNumbers.removeAt(0);
            }
            // Adicionar novos controles para cada telefone monitorado
            user.monitored_phones.forEach(item => {
              this.monitoredNumbers.push(new FormControl(item.phone_number, Validators.pattern(/^\+?\d{10,15}$/)));
            });
          });
        }
      })
    );

    this.subscriptions.push(
      this.providerAccountService.listAll().subscribe(accounts => {
        this.providerAccounts = accounts;
      })
    );

    this.profiles$ = this.profileService.listAll();
    this.shorteners$ = this.shortenerService.listAll();
    this.userService.getOperationTypes().subscribe(types => {
      this.operationTypes = types;
    });
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // Getter for the currentMonitoredPhone as FormControl
  get currentMonitoredPhoneControl(): FormControl {
    return this.userForm.get('currentMonitoredPhone') as FormControl;
  }
    
  addMonitoredNumber(): void {
    const phone = this.userForm.get('currentMonitoredPhone').value.replace(/\D/g, "");
    if(phone.length < 11){
      this.toastService.info('Número inválido, o numero precisa ter 11 digitos.');
      return;
    }
      
    const alreadyExists = this.monitoredNumbers.controls.some(ctrl => ctrl.value === phone);
    if (alreadyExists) {
      // Display a toast message if the number already exists
      this.toastService.info('Número já cadastrado.');
      return;
    }
    
    // Add the number if it does not exist
    this.monitoredNumbers.push(new FormControl(phone));
    this.userForm.get('currentMonitoredPhone').setValue(''); // Clear input 
  }

  removeMonitoredNumber(index: number): void {
    this.monitoredNumbers.removeAt(index);
  }

  toggleGeneratePassword(): void {
    const newPassword = this.generatePassword ? 'GENERATED_PASSWORD' : '';
    this.userForm.patchValue({
      password: newPassword,
      password_confirmation: newPassword
    });
  }

  onSwitchChange(value: boolean): void {
    this.exportLeads = value; 
    this.UtilService.setExportLeads(this.userId, value)
  }

  getfractionationGroup(){
    this.fractionationService.getRoutes().subscribe({
      next: (data: Fractionation[]) => {
            this.fractionationGroups = data;
      },
      error: (error) => {
        this.toastService.error('Erro ao buscar as rotas');
      }
    });
  }


  save(): void {
    const user = this.userForm.getRawValue() as User;
    user.active = this.active;
    user.credit_payment_enabled = this.creditPaymentEnabled;
    user.pix_priority = this.pixPriority;
    user.massive_sms_enabled = this.massiveSmsEnabled;
    user.content_tag_enabled = this.contentTagEnabled;
    user.analytics_sync = this.analyticsSync;
    user.fake_request = this.fakeRequest;
    user.leads_export = this.exportLeads;

    user.monitored_phones = this.monitoredNumbers.controls.map(control => ({
      user_id: user.id ,
      phone_number: control.value
    }));
    
    const getStausFractionationRoutesForAutomation = this.fractionationGroups.find(route => route.id === this.userForm.get('automation_fractionation_route').value);
    const getStausFractionationRoutesForBroadcast = this.fractionationGroups.find(route => route.id === this.userForm.get('broadcast_fractionation_route').value);

    if(getStausFractionationRoutesForAutomation && getStausFractionationRoutesForBroadcast){
        if(getStausFractionationRoutesForAutomation.active === false ){
         return this.toastService.error('Rota de automação escolhida está desativada');
        }
        if(getStausFractionationRoutesForBroadcast.active === false){
          return this.toastService.error('Rota de broadcast escolhida está desativada');
        }
    }

    this.fractionationService.getRoutes().subscribe({
      next: (data: Fractionation[]) => {
        if (data.length === 0) {
          this.toastService.error('Nenhuma rota cadastrada');
          return;
        }
        // Find and log the first monitored route, if exists
        const monitoredRoute = data.find(route => route.monitored === true);
        if (monitoredRoute) {
          this.fractionation_group_id = monitoredRoute.id;


        } else {
          return this.toastService.error('Nenhuma rota monitorada cadastrada');
        }
      },
      error: (error) => {
        this.toastService.error('Erro ao buscar as rotas');
      }
    });
    
    const successMessage = user.id ? 'Usuário alterado com sucesso!' : 'Usuário inserido com sucesso!';
    if (user.commission > 12) {
      this.toastService.error('A comissão máxima é 12%.');
      return;
    }

    this.subscriptions.push(
      this.userService.save(user).subscribe(savedUser => {
        let message = successMessage;
        if (savedUser.password) {
          message += `\nNova senha: ${savedUser.password}`;
        }
        this.toastService.success(message);
        if (!this.userId) {
          this.router.navigate(['/users/edit', savedUser.id]);
        }
      })
    );
  }
}
