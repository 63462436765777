import { ADMINISTRATOR, CLIENT, PARTNER_CLIENT, AGENCY_CLIENT, CUSTOMER_SUCCESS } from './../../../shared/services/user.service';
import { MenuItem } from './menu-item';

export const menuItens: MenuItem[] = [
  {
    path: '/dashboard',
    text: 'Dashboard',
    icon: 'fa-tachometer',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/provider',
    text: 'Provedores',
    icon: 'fa-server',
    simulated: true,
    global: true,
    profiles: [
       ADMINISTRATOR
    ]
  },
  {
    path: '/partner',
    text: 'Parceiros',
    icon: 'fa-fire',
    simulated: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      PARTNER_CLIENT
    ]
  },
  {
    path: '/lists',
    text: 'Listas',
    icon: 'fa-list',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/campaigns',
    text: 'Automações',
    icon: 'fa-envelope-o',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/broadcasts',
    text: 'SMS Massivo',
    icon: 'fa-bullhorn',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/broadcasts-whatsapp',
    text: 'Whatsapp Massivo',
    icon: 'fa-whatsapp',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },

  {
    path: '/fractionations',
    text: 'Fracionamentos',
    icon: 'fa-list-alt',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR
    ]
  },
  {
    path: '/messages-report',
    text: 'Mensagens',
    icon: 'fa-envelope',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/lead-responses',
    text: 'Respostas',
    icon: 'fa-envelope-o',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/recharges',
    text: 'Recarga',
    icon: 'fa-money',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS,
    ]
  },
  {
    path: '/integrations',
    text: 'Integrações',
    icon: 'fa-rocket',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS,
    ]
  },
  {
    path: '/users',
    text: 'Usuários',
    icon: 'fa-users',
    global: true,
    profiles: [
      ADMINISTRATOR,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/short-url-analysis',
    text: 'Analisar Sites',
    icon: 'fa-search',
    global: true,
    profiles: [
      ADMINISTRATOR
    ]
  },
  {
    path: '/broadcasts-filter',
    text: 'Analisar SMS Massivo',
    icon: 'fa-users',
    global: true,
    profiles: [
      ADMINISTRATOR      
    ]
  },
  {
    path: '/partner-management',
    text: 'Agências',
    icon: 'fa-bank',
    global: true,
    profiles: [
      ADMINISTRATOR,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/reports',
    text: 'Relatórios',
    icon: 'fa-file',
    global: true,
    profiles: [
      ADMINISTRATOR
    ]
  },
  {
    path: '/consumption-report',
    text: 'Relatorio de Consumo',
    icon:'fa-file-text-o',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/analytics',
    text: 'Relatórios',
    icon: 'fa-pie-chart',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  },
  {
    path: '/financial',
    text: 'Fiscal',
    icon: 'fa-newspaper-o',
    global: true,
    profiles: [
      ADMINISTRATOR
    ]
  },
  
  {
    path: '/help',
    text: 'Tutorial',
    icon: 'fa-comments',
    simulated: true,
    global: true,
    profiles: [
      ADMINISTRATOR,
      AGENCY_CLIENT,
      CLIENT,
      PARTNER_CLIENT,
      CUSTOMER_SUCCESS
    ]
  }
];
