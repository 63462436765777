import { BroadcastContact } from './../../models/BroadcastContact';

import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

import { Broadcast } from 'src/app/models/Broadcast';
import { PaginatedResult } from './../model/paginated-result';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CANCELLED, IMPORTING } from 'src/app/models/BroadcastStatus';
import { removeInvalidCharacters } from '../utils';
import { LeadList } from 'src/app/models/LeadList';
import { Lead } from 'src/app/models/Lead';

const STORAGE_KEY = 'SMSFUNNEL::BROADCASTS';

let interval: any = null;
const importFinished = new EventEmitter<Broadcast>();

@Injectable({ providedIn: 'root' })
export class BroadcastsService {
  get importFinished(): EventEmitter<Broadcast> {
    return importFinished;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  isImporting(broadcast: Broadcast): boolean {
    return broadcast.importing || broadcast.broadcast_status_id === IMPORTING;
  }

  isCancelled(broadcast: Broadcast): boolean {
    return broadcast.error || broadcast.broadcast_status_id === CANCELLED;
  }

  findById(id: string): Observable<LeadList> {
    return this.http.get<LeadList>(`${environment.apiUrl}/broadcasts/${id}`);
  }

  status(id: string): Observable<Broadcast> {
    const url = `${environment.apiUrl}/broadcasts/${id}/status?no-loading=true`;
    return this.http.get<Broadcast>(url).pipe(take(1));
  }

  listContacts(id: string, page: number = 1, perPage: number = 15): Observable<PaginatedResult<Lead[]>> {
    return this.http.get<PaginatedResult<Lead[]>>
      (`${environment.apiUrl}/broadcasts/${id}/contacts?page=${page}&per_page=${perPage}`);
  }

  listPaginated(page: number = 1, perPage: number = 15, startDate = null, endDate = null, text = null, orderBy = null, invertedOrder= null): Observable<PaginatedResult<LeadList[]>> {
    let url = `${environment.apiUrl}/broadcasts?page=${page}&per_page=${perPage}`;

    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    if (endDate) {
      url += `&endDate=${endDate}`;
    } 

    if (text) {
      url += `&text=${text}`
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`
    }

    if (invertedOrder) {
      url += `&invertedOrder=${invertedOrder}`
    }

    return this.http.get<PaginatedResult<LeadList[]>>(url);
  }

  send(broadcast: Broadcast): Observable<Broadcast> {
    broadcast.message = removeInvalidCharacters(broadcast.message);
    return this.http.post<Broadcast>(`${environment.apiUrl}/broadcasts`, broadcast);
  }

  update(broadcast: Broadcast): Observable<Broadcast> {
    return this.http.put<Broadcast>(`${environment.apiUrl}/broadcasts/${broadcast.id}`, broadcast);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/broadcasts/${id}`);
  }

  cancel(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/broadcasts/${id}/cancel`, {});
  }

  getTotalSent(statisticsStartDate = null, statisticsEndDate = null): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/broadcasts/total-sent?endDate=${statisticsEndDate}&startDate=${statisticsStartDate}`);
  }

  resend(broadcastId: string, providerAccountId: string): Observable<Broadcast> {
    return this.http.post<Broadcast>(`${environment.apiUrl}/broadcasts/resend`, { broadcastId, providerAccountId });
  }

  getAllBroadcasts(
    page: number = 1,
    perPage: number = 15,
    startDate?: string,
    endDate?: string,
    message?: string,
    orderBy?: string,
    invertedOrder?: boolean,
    name?: string,
    providerAccountId?: string, 
  ): Observable<PaginatedResult<Broadcast[]>> {
    let url = `${environment.apiUrl}/all-broadcasts?page=${page}&per_page=${perPage}`;

    if (startDate) {
      url += `&start_date=${startDate}`;
    }

    if (endDate) {
      url += `&end_date=${endDate}`;
    }

    if (message) {
      url += `&message=${message}`;
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }

    if (invertedOrder) {
      url += `&invertedOrder=${invertedOrder}`;
    }

    if (name) {
      url += `&name=${name}`;
    }

    if (providerAccountId) {
      url += `&provider_account_id=${providerAccountId}`;
    }
    

    return this.http.get<PaginatedResult<Broadcast[]>>(url);
  }
}
