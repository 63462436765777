import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { Month } from 'src/app/models/Month';
import { PaginatedResult } from 'src/app/shared/model/paginated-result';
import { UserService } from 'src/app/shared/services/user.service';
import { ConsumptionReportService } from 'src/app/shared/services/consumption-report.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { ConsumptionReportFilter } from 'src/app/models/Consumption-report';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ToastService } from '../../shared/components/toast/toast.service';


@Component({
  selector: 'app-consumption-report',
  templateUrl: './consumption-report.component.html',
  styleUrls: ['./consumption-report.component.scss'],
})
export class ConsumptionReport implements OnInit, OnDestroy {
  page = 1;
  perPage = 10;
  user: User;
  users: User[];
  filteredUsers: User[];
  years: number[];
  months: Month[];
  form: FormGroup;
  subscriptions: Subscription[] = [];
  result$: Observable<PaginatedResult<ConsumptionReportFilter[]>>;
  filteredReports: ConsumptionReportFilter[] = [];
  userSearch: string = '';  

  constructor(
    
    private route: ActivatedRoute,
    private fb: FormBuilder,    
    private utilService: UtilService,
    private consumptionReportService: ConsumptionReportService,
    private userService: UserService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    const year = this.utilService.getYear();
    const month = this.utilService.getMonth();

    this.years = this.utilService.listYearsApp();
    this.months = this.utilService.listMonths();

    this.loadUsers();

    this.form = this.fb.group({
      month: [month, [Validators.required]],
      year: [year, [Validators.required]],
      user: [null],
      userSearch: [''],      
    });

    this.form.get('userSearch')?.valueChanges.subscribe(value => {
      if (!value) {
        this.form.patchValue({ user: null });
      }
      this.filterUsers(value);
    });

    this.subscriptions.push(
      this.route.queryParams.subscribe((params: Params) => {
        this.page = +params.page || this.page;
        this.perPage = +params.per_page || this.perPage;

        const formValues = { ...this.form.value };

        formValues.month = +params.month || formValues.month;
        formValues.year = +params.year || formValues.year;

        if (params.user) {
          formValues.user = params.user;
        }

        this.form.patchValue(formValues);
        this.handleFilter();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  loadUsers(): void {
    this.userService.listAll().subscribe((users) => {
      this.users = users;
      this.filteredUsers = users;
    });
  } 

  filterUsers(search: string): void {
    const lowerSearch = search.toLowerCase();
    this.filteredUsers = this.users.filter(user => user.name.toLowerCase().includes(lowerSearch));    
  }

  handleUserSelection(event: MatAutocompleteSelectedEvent): void {
    const selectedUserId = event.option.value;
    const selectedUser = this.users.find(user => user.id === selectedUserId);    
    if (selectedUser) {
      this.form.get('userSearch').setValue(selectedUser.name);
      this.form.patchValue({ user: selectedUser.id });
    } else {
      this.form.get('userSearch').setValue('');
      this.form.patchValue({ user: null });
    }
  }

  displayUser(userId: string): string {
    const user = this.users.find(user => user.id === userId);
    return user ? user.name : '';
  }

  getUserById(userId: string): User | undefined {
    return this.users.find(user => user.id === userId);
  }

  handleFilter(): void {
    const formValues = this.form.value;
    
    const filter: ConsumptionReportFilter = {
      month: formValues.month,
      year: formValues.year,
      user_id: formValues.user
    };

    this.result$ = this.consumptionReportService.getConsumptionReport(
      this.page,
      this.perPage,
      filter
    );
  }

  pageChanged(page: number) {
    this.page = page;
    this.handleFilter();
  }

  generateAndDownloadCsv(): void {

    const formValues = this.form.value;
    const userId = formValues.user; 

    if (!userId) {
      this.toastService.error('Preencha o Usuário.');
      return;
    }

    const filter: ConsumptionReportFilter = {
        month: formValues.month,
        year: formValues.year,
        user_id: userId,
    };
    
    this.consumptionReportService.downloadCsv(filter).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
        a.href = url;
        a.download = 'consumption-report.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
  }
}

