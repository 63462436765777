// src/app/services/routes.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'

export interface ProviderType {
  providerControl: string;
  percentageControl: number;
}
export interface FractionGroupProvider {
  id?: string;
  carrier_id: string;
  name: string;
  fractionation_group_id: string;
  percentageControl: number;
  percentage: string;
  providers: ProviderType[];
  provider_account_id: string;
  percentage_provider: string;
}

@Injectable({
  providedIn: 'root'
})
export class FractionGroupProviderService{

  constructor(private http: HttpClient) { }


  addRow(values: FractionGroupProvider[]): Observable<FractionGroupProvider> {
    const payload = {
      fractionationGroups: values
    }
    return this.http.post<FractionGroupProvider>(`${environment.apiUrl}/fractionation-groups-provider-accounts-pivot`, payload);
  }

  getRowsByFractionationId(fractionationId: string): Observable<FractionGroupProvider[]> {
    return this.http.get<FractionGroupProvider[]>(`${environment.apiUrl}/fractionation-groups-provider-accounts-pivot/${fractionationId}`);
  }

  deleteRow(fractionationId: string): Observable<FractionGroupProvider> {
    return this.http.delete<FractionGroupProvider>(`${environment.apiUrl}/fractionation-groups-provider-accounts-pivot/${fractionationId}`);
  }



  


  
  
  

}
