// src/app/services/routes.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'

export interface Fractionation {
  id?: string;
  name: string;
  monitored: boolean;
  default: boolean;
  created_at: Date;
  active: boolean;
  configured: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FractionationService {

  constructor(private http: HttpClient) { }

  getRoutes(): Observable<Fractionation[]> {
    return this.http.get<Fractionation[]>(`${environment.apiUrl}/fractionations`);
  }

  addRoute(route: Fractionation): Observable<Fractionation> {
    return this.http.post<Fractionation>(`${environment.apiUrl}/fractionations`, route);
  }

  changeGroupActive(route: Fractionation): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/fractionations/changeGroupActive/${route.id}`, {
      active: route.active
    });
  }

  getRoute(id: string): Observable<Fractionation> {
    return this.http.get<Fractionation>(`${environment.apiUrl}/fractionations/${id}`);
  }
}
