<!DOCTYPE html>
<html lang="pt-br">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>

<body>
    <div class="card elevated">
        <div class="card-body">
            <h3>Cadastro e gerenciamento de rotas</h3>

            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div style="position: relative; flex-grow: 1; margin-right: 458px;">
                    <input
                        type="text"
                        name="filter"
                        id="filter"
                        class="form-control"
                        placeholder="Buscar...."
                        style="padding-right: 30px;" 
                        [(ngModel)]="filterText"
                        (input)="filterRouteName()"
                    />
                    <i class="fa fa-search" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"></i>
                </div>
                <button (click)="openModal()" class="btn btn-primary btn-icon-right">
                    <span style="margin-right: 27px;">Adicionar configuração</span>
                    <i class="fa fa-plus-circle"></i>
                </button>
            </div>
            

            <table class="table table-hover elevated">
                <thead>
                    <tr>
                        <th style="width: 300px">Nome da Rota</th>
                        <th style="text-align: center; width: 170px;">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let route of result.data">
                        <td>{{ route.name }}</td>
                        <td style="text-align: right;">
                            <ng-container *ngIf="route.default && !route.monitored">
                                <span style="background-color: green; color: white; padding: 2px 6px;margin-right: 40px;">
                                    SAIDA_PADRAO
                                </span>
                            </ng-container>
                            <ng-container *ngIf="route.monitored && !route.default">
                                <span style="background-color: green; color: white; padding: 2px 6px; margin-right: 40px;">
                                    MONITORADA
                                </span>
                            </ng-container>
                         

                            <button class="btn" (click)="redirectToEdit(route)">
                                <i class="fa fa-pencil" aria-hidden="true" style="font-size: medium;"></i>
                                <span style="font-size: medium; margin-left: 5px;">
                                    Editar
                                </span>
                            </button>
                            
                            <ng-container *ngIf="!route.active">
                                <!-- Se ambos default e monitored forem falsos, mostre o botão Ativar -->
                                <button class="btn" (click)="changeGroupActive(route)">
                                    <i class="fa fa-plus-circle" aria-hidden="true" style="font-size: large;"></i>
                                    <span style="font-size: medium; margin-left: 5px;">
                                        Ativar
                                    </span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="route.active">
                                <!-- Caso contrário, mostre o botão Desativar -->
                                <button class="btn" (click)="changeGroupActive(route)">
                                    <i class="fa fa-minus-circle" aria-hidden="true" style="font-size: large;"></i>
                                    <span style="font-size: medium; margin-left: 5px;">
                                        Desativar
                                    </span>
                                </button>
                            </ng-container>
                            
                           
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal" [class.show]="isModalOpen" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Nome da configuração</h5>
                    <button type="button" class="close" (click)="isModalOpen = false">
                        &times;
                    </button>
                </div>
                <div class="modal-body">
                    <input
                    type="text"
                    name="routeName"
                    id="routeName"
                    class="form-control"
                    placeholder="Digite o nome da configuração"
                    style="padding-right: 30px;" 
                /> 
                
                <div style="margin-top: 30px;">
                    <label class="label-checkbox">
                        <input type="checkbox" [checked]="default" (change)="checkboxDefault()">
                        <span style="margin-left: 8px;">Usar como padrão</span>
                        <span style="margin-left: 70px; color: red;" id="previousDefaulRouteMessage"></span>
                    </label>
                </div>
                <div>
                    <label class="label-checkbox">
                        <input type="checkbox" [checked]="monitored" (change)="checkboxMonitored()">
                        <span style="margin-left: 8px;">Saída monitorada</span>
                        <span style="margin-left: 70px; color: red;" id="previousMonitoredRouteMessage"></span>

                    </label>
                </div>
                
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="isModalOpen = false">Fechar</button>
                    <button type="button" class="btn btn-primary" (click)="createConfiguration()">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</body>

</html>