import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fractionation, FractionationService } from 'src/app/shared/services/fractionations.service';
import { Observable} from 'rxjs';
import { FormBuilder, FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Carrier, CarrierService } from 'src/app/shared/services/carrier.service';
import { ProviderAccountService } from 'src/app/shared/services/provider-account.service';
import { ProviderAccount } from 'src/app/models/ProviderAccount';
import { FractionGroupProvider, FractionGroupProviderService } from 'src/app/shared/services/fractionation-provider-pivot.service';
import { ToastService } from 'src/app/shared/components/toast/toast.service';

@Component({
  selector: 'app-fractionation',
  templateUrl: './fractionation.component.html',
  styleUrls: ['./fractionation.component.scss']
})
export class FractionationComponent implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  routeData: Fractionation;
  carrierOptions: Carrier[] = [];
  providerAccountOptions: ProviderAccount[] = [];
  providerControl = new FormControl('');
  form: FormGroup;
  filteredCarrierOptions: Observable<Carrier[]>;
  filteredProviderOptions: Observable<ProviderAccount[]>;
  isConfigured = false;
  remainPercentValue: number = 100;
  defaultCarrier: Carrier;
  previousProviderValue: ProviderAccount | null = null;
  validatePercentage: boolean = false;

  carriersProvidersData: FormArray;

  constructor(
    private fractionationService: FractionationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private carrierService: CarrierService,
    private providerAccount: ProviderAccountService,
    private fb: FormBuilder,
    private FractionGroupProviderService: FractionGroupProviderService,
    private toastService: ToastService,

  ) { 
  }

  ngOnInit() {
    this.form = this.fb.group({
      carriers: this.fb.array([]),
    });

    this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.fetchRoute(id);
      }
    });

    this.fetchCarriers();
    this.fetchProviders();
  }

 
  get carriers(): FormArray {
    return this.form.get('carriers') as FormArray;
  }

  removeCarrierRow(index: number) {
    this.carriers.removeAt(index);
  }

  addCarrierRow(carrier: any = null, percentage: number = 100): FormGroup {
    if(!carrier) {
      carrier = this.carrierOptions.find(c => c.title == 'Qualquer operadora');
    }

    const carrierGroup = this.fb.group({
      percentageControl: new FormControl(percentage),
      carrierControl: new FormControl(carrier),
      providers: this.fb.array([])
    });

    this.carriers.push(carrierGroup);

    this.updateCarrierFilter(carrierGroup.get('carrierControl') as FormControl);
    return carrierGroup;
  }
  
  updateCarrierFilter(control: FormControl) {
    this.filteredCarrierOptions = control.valueChanges.pipe(
      startWith(''),
      map(value => this._carrierFilter(value))
    );
}

  private _carrierFilter(value: any): Carrier[] {
    let filterValue = '';
 
    if(typeof value == 'string') {
      filterValue = value?.toLowerCase();
    } else {
    filterValue = value.title?.toLowerCase();
    }
    
    return this.carrierOptions.filter(option => option.title.toLowerCase().includes(filterValue));
  }

  getProviderArray(carrierGroup: AbstractControl): FormArray {
    return carrierGroup.get('providers') as FormArray;
  }

  removeProviderRow(group: AbstractControl, index: number) {
    this.getProviderArray(group).removeAt(index);
    
  }

  addProviderRow(carrierGroup: AbstractControl, provider: any = null, percentage: string = ''): void {
    const providers = this.getProviderArray(carrierGroup);
    this.providerControl.setValue(provider);
    const providerGroup = this.fb.group({
      providerControl: new FormControl(provider),
      percentageControl: new FormControl(percentage)
    });

    providers.push(providerGroup);

  this.updateProviderFilter(providerGroup.get('providerControl') as FormControl);
  }

  updateProviderFilter(control: FormControl) {
    this.filteredProviderOptions = control.valueChanges.pipe(
      startWith(''),
      map(value => this._filterProviders(value))
    );
  }

  private _filterProviders(value: any ): ProviderAccount[] {
    let filterValue = ''

    if(typeof value == 'string') {
      filterValue = value?.toLowerCase();
    } else {
      filterValue = value.name?.toLowerCase();
    }

    return this.providerAccountOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getProviders(carrierIndex: number): FormArray{
    return this.carriers.at(carrierIndex).get('providers') as FormArray;
  }


  fetchRoute(id: string) {
    this.fractionationService.getRoute(id).subscribe({
      next: (data: Fractionation) => {
        this.routeData = data;
        this.isConfigured = data.configured;
  
          this.FractionGroupProviderService.getRowsByFractionationId(this.routeData.id).subscribe({
          next: (data: any) => {
            if (typeof data === 'object' && !Array.isArray(data)) {
              this.populateForm(data);
              this.carriersProvidersData = data as FormArray;
            } else {
              console.error('Erro: dados recebidos não estão no formato esperado:', data);
            }
          },
          error: (error) => {
            console.error('Erro ao obter os dados de fractionation provider pivot:', error);
          }
        });
      },
      error: (error) => {
        console.error('Erro ao obter a rota de fractionation:', error);
      }
    });
  }
  
  populateForm(data: { [carrier_id: string]: FractionGroupProvider[] }) {
    this.fetchCarriers();
    Object.keys(data).forEach((carrierId) => {
      const carrier = this.carrierOptions.find(c => c.id == carrierId);
      if (!carrier) {
        console.error('Erro: operadora não encontrada:', carrierId);
        return;
      }
      this.addCarrierRow(carrier, 0);
      this.carriers.controls[this.carriers.length - 1].get('percentageControl').setValue('0');
      const providers = data[carrierId];
      console.log('PROVIDERS ----> ', providers);
      
      providers.forEach((provider) => {
        const providerAccount = this.providerAccountOptions.find(p => p.id === provider.provider_account_id);
        if (providerAccount) {
          this.addProviderRow(this.carriers.controls[this.carriers.length - 1], providerAccount, provider.percentage_provider);
        } else {
          console.error('Erro: provider account não encontrado:', provider.provider_account_id);
        }
      });
    });
  }
  
  fetchCarriers() {
    this.carrierService.listAll().subscribe({
      next: (data: Carrier[]) => {
        this.carrierOptions = data;
      },
      error: (error) => {
        console.error('Erro ao obter os dados de carrier:', error);
      }
    });
  }

  fetchProviders() {
    this.providerAccount.listAll().subscribe({
      next: (data: ProviderAccount[]) => {
        this.providerAccountOptions = data;
        
      },
      error: (error) => {
        console.error('Erro ao obter os dados de provider account:', error);
      }
    });
  }

  redirectBack() {
    this.router.navigate(['/fractionations']);
  }

  saveValues() {
    const values = this.form.value.carriers.map((carrier: any) => {
      return {
        id: carrier.id,
        carrier_id: carrier.carrierControl.id,
        name: carrier.carrierControl.title,
        fractionation_group_id: this.routeData.id,
        percentageControl: carrier.percentageControl,
        percentage: carrier.percentageControl,
        providers: carrier.providers.map((provider: any) => {
          return {
            providerControl: provider.providerControl.id,
            percentageControl: provider.percentageControl
          }
        })
      }
    }
    );
    
  this.carriers.controls.forEach((carrierGroup: FormGroup) => {
  const totalPercentageControl = carrierGroup.value.providers.reduce((sum: number, provider: any) => {
    return sum + (parseFloat(provider.percentageControl) || 0);
  }, 0);

  const remainingPercentageControl = 100 - totalPercentageControl;
  carrierGroup.get('percentageControl').patchValue(remainingPercentageControl);

  if (remainingPercentageControl !== 0) {
    this.toastService.info('A soma das porcentagens das operadoras deve ser igual a 100%');
    this.validatePercentage = true;
    return;
  }
});
    if(this.validatePercentage) {
      this.validatePercentage = false;
      return;
    }

    if (this.form.value.carriers.length == 0) {
      this.toastService.info('Adicione pelo menos uma operadora');
      return
    }
    if (this.form.value.carriers.some((carrier: any) => carrier.providers.length == 0)) {
      this.toastService.info('Adicione pelo menos uma saída  para cada operadora');
      return
    }
    if (this.form.value.carriers.some((carrier: any) => carrier.providers.some((provider: any) => provider.percentageControl == 0))) {
      this.toastService.info('A porcentagem das saídas es deve ser maior que 0');
      return
    }
    if (this.form.value.carriers.some((carrier: any) => carrier.providers.some((provider: any) => provider.percentageControl == ''))) {
      this.toastService.info('A porcentagem das saídas es deve ser preenchida');
      return
    }

   this.FractionGroupProviderService.addRow(values).subscribe({
    
    next: (response) => {
      this.toastService.success('Configuração salva com sucesso');
    },
    error: (error) => {
      this.toastService.error('Erro ao salvar configuração');
    }
  });
  }

  displayCarrierFn(carrier?: Carrier): string | undefined {
    return carrier ? carrier.title : undefined;
  }

  displayProviderFn(provider?: ProviderAccount): string | undefined {
    return provider ? provider.name : undefined;
  }

  updateTotalPercentage() {
    const total = this.carriers.controls.reduce((sum, group: FormGroup) => {
      return sum + (parseFloat(group.get('percentageControl').value) || 0);
    }, 0);
    this.form.get('totalPercentage').setValue(total);
  }

  loadCarrierAutoComplete(index: number) {
    const carrierControl = this.carriers.controls[index].get('carrierControl') as FormControl;
    this.updateCarrierFilter(carrierControl);
  }
  
  loadProviderAutoComplete(carrier: any, index: number ) {
    const providerGroup = carrier.get('providers') as FormArray;
    const providerControl = providerGroup.at(index).get('providerControl') as FormControl;
    const providerId = providerControl.value.id;
    this.updateProviderFilter(providerId);
  }
}
