<ng-container *ngIf="routeData">
 
  <div class="container">
    <div class="div-container">
      <img src="assets/icon-routes.svg" alt="Detalhamento de rota" width="44" height="64">
      <span class="titulo">Detalhamento de rota: ({{ routeData.name }})</span>
    </div>
    <div class="btn-group">

      

      <button (click)="redirectBack()" class="btn btn-primary">Voltar</button>
      <button class="btn button-save" (click)="saveValues()">
        Salvar
      </button>
      
    </div>
  </div>
  

  <div class="new-carrier-container">
    <button (click)="addCarrierRow()" class="btn btn-new-carrier">
      Adicionar nova operadora
      <i class="fa fa-plus-circle"></i>
    </button>
  </div>

  <div class="card elevated">
    <div class="card-body">
      <form [formGroup]="form">

        <mat-autocomplete #autoCompleteCarrier="matAutocomplete" [displayWith]="displayCarrierFn">
          <mat-option *ngFor="let option of filteredCarrierOptions | async" [value]="option">
            {{option.title}}
          </mat-option>
        </mat-autocomplete>

        <mat-autocomplete  #autoCompleteProvider="matAutocomplete" [displayWith]="displayProviderFn">
          <mat-option *ngFor="let option of filteredProviderOptions | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>

        <div formArrayName="carriers">
          <mat-accordion>
            <div *ngFor="let carrier of carriers.controls; let i = index" [formGroupName]="i" class="bordered-div">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Operadora {{ i + 1 }} - {{ carrier.get('carrierControl').value?.title ?? defaultCarrier?.title}} (Porcentagem restante para distribuir: {{ carrier.get('percentageControl').value}}%)
                  </mat-panel-title>
                  <button mat-icon-button type="button" (click)="removeCarrierRow(i)" matTooltip="Remover Operadora">
                    <mat-icon class="red-icon">delete</mat-icon>
                  </button>
                </mat-expansion-panel-header>
          
                <div class="carrier-title">
                  <span>Operadora</span>
                </div>
                <div class="carrier-container-fields">
                  <mat-form-field appearance="fill" class="mat-form-field-custom">
                    
                  
                    <mat-label>Selecione a operadora</mat-label>
                    <input type="text" matInput formControlName="carrierControl" [matAutocomplete]="autoCompleteCarrier" (focus)="loadCarrierAutoComplete(i)" />
                  </mat-form-field>

                </div>
          
                <div class="provider-container-fields">
                  <span>Saídas</span>
                  <button class="btn btn-add-exit" type="button" (click)="addProviderRow(carrier)">
                    <i class="fa fa-plus-circle"></i>
                    Fracionar
                  </button>
                  <span>Porcentagem distribuida</span>
                </div>
          
                <div formArrayName="providers">
                  <div class="provider-container" *ngFor="let provider of getProviders(i).controls; let j = index" [formGroupName]="j">
                    <mat-form-field class="mat-form-field-custom">
                      <mat-label>Conta de provedor</mat-label>
                      <input type="text"
                        matInput
                        [matAutocomplete]="autoCompleteProvider"
                        formControlName="providerControl"
                        (focus)="loadProviderAutoComplete(carrier, j)"
                        >
                        
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill">
                      <mat-label>Percentual Distribuida</mat-label>
                      <input matInput type="number" formControlName="percentageControl" />
                    </mat-form-field>
                    <button mat-icon-button type="button" (click)="removeProviderRow(carrier, j)" matTooltip="Remover saída">
                      <mat-icon class="red-icon">delete</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </form>

    </div>
  </div>
</ng-container>