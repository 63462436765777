import { Component, OnDestroy, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IMPORTING, SCHEDULED, SENT, SENDING, CANCELLED } from '../../models/LeadListStatus';
import { User } from '../../models/User';
import { ConfirmationService } from '../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { AuthService } from '../../shared/services/auth.service';
import { ADMINISTRATOR } from '../../shared/services/user.service';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { BroadcastsService } from './../../shared/services/broadcasts.service';
import {UserCreditInfo} from "../../models/UserCreditInfo";
import {DashboardService} from "../../shared/services/dashboard.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderAccountService } from '../../shared/services/provider-account.service';
import { ProviderAccount } from '../../models/ProviderAccount';
import { LeadList } from 'src/app/models/LeadList';

@Component({
  selector: 'app-broadcasts',
  templateUrl: './broadcasts.component.html',
  styleUrls: ['./broadcasts.component.scss']
})
export class BroadcastsComponent implements OnInit, OnDestroy {
  @ViewChild('modalBroadcast', {static: true}) modalBroadcast: ElementRef<any>;
  @ViewChild('modalAccept', {static: true}) modalAccept: ElementRef<any>;

  @Input() form: UntypedFormGroup;

  text: string;
  perPage = 10;
  startedMonth: Date;
  endedMonth: Date;
  startDate: string;
  endDate: string;
  invalidTime: boolean;
  orderBy = 'created_at';
  invertedOrder = true;
  
  page: number;
  pageSize = 10;
  originalUser: User;
  user: User;
  loading: boolean;
  result: PaginatedResult<LeadList[]>;
  subscriptions: Subscription[] = [];

  statisticsToggled: boolean = false;
  statisticsClass: string = 'appear';

  statisticsTotal = 0;
  statisticsTotalClass = '';
  statisticsEstimated = 0;

  statisticsStartDate: Date;
  statisticsEndDate: Date;
  statisticsFailed: boolean = false;

  userCreditInfo: UserCreditInfo;

  showBroadcastsStatistics: boolean = false;
  providerAccounts: ProviderAccount[] = [];
  broadcastForm: UntypedFormGroup;
  defaultProviderAccountId: string;

  get administrator() {
    return this.originalUser.profile_id === ADMINISTRATOR;
  }

  constructor(
    private broadcastsService: BroadcastsService,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private providerAccountService: ProviderAccountService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.broadcastForm = this.formBuilder.group({
      broadcastId: [''],
      providerAccountId: [''],
    });

    this.defaultProviderAccountId = this.authService.getUser().broadcast_provider_account_id;

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.page = params.page || this.page;
        this.pageSize = params.page_size || this.pageSize;
        this.startDate = params.started_To_Date ?? '';
        this.endDate = params.ended_To_Date ?? '';
        this.text = params.text || '';
        this.orderBy = params.orderBy || this.orderBy; 
        this.invertedOrder = params.invertedOrder === 'true' || this.invertedOrder;
        this.listPaginated();
      }),

      this.authService.userChanged.subscribe(() => this.listPaginated())
    );

    this.subscriptions.push(
      this.dashboardService.userCreditsInfo()
        .subscribe(userCreditInfo => this.userCreditInfo = userCreditInfo)
    )

    this.subscriptions.push(
      this.providerAccountService.listAll()
        .subscribe(providerAccounts => this.providerAccounts = providerAccounts),
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getTitle(broadcast: LeadList) {
    let title = broadcast.name;

    title += ` - ${broadcast.type?.type} `

    if (this.isImporting(broadcast)) {
      title = '[importando...] ' + title;
    }
    else if (this.isCancelled(broadcast)) {
      title = '[Cancelado] ' + title;
    }

    title += `(${broadcast.leads_count})`;

    return title;
  }

  isScheduled(broadcast: LeadList): boolean {
    return broadcast.status_id === SCHEDULED;
  }

  isSent(broadcast: LeadList): boolean {
    return broadcast.status_id === SENT;
  }

  isHiddenButton(broadcast: LeadList): boolean {
    return broadcast.status_id !== SENT;
  }

  isImporting(broadcast: LeadList): boolean {
    return broadcast.status_id == IMPORTING;
  }

  isCancelled(broadcast: LeadList): boolean {
    return broadcast.status_id == CANCELLED;
  }

  isSending(broadcast: LeadList): boolean {
    return broadcast.status_id == SENDING;
  }

  listPaginated(startDate = null, endDate = null): void {
    this.user = this.authService.getUser();
    this.originalUser = this.authService.getUser(true)
    

    this.subscriptions.push(
      this.broadcastsService.importFinished.subscribe(() => this.listPaginated())
    );
    
    if (!startDate && this.startedMonth) {
      startDate = this.startedMonth.toISOString().split('T')[0];
    }

    if (!endDate && this.endedMonth) {
      endDate = this.endedMonth.toISOString().split('T')[0];
    }

    if (this.user && !this.user.validated && !this.administrator) return;

    this.loading = true;

    this.subscriptions.push(
      this.broadcastsService.listPaginated(this.page, this.pageSize, startDate, endDate, this.text, this.orderBy, this.invertedOrder)
        .pipe(finalize(() => this.loading = false))
        .subscribe(result => {
          this.result = result;
        })
    )
  }

  pageChanged(page: any): void {
    const queryParams = {  page, per_page: this.perPage };

    if (this.text) {
      queryParams['text'] = this.text;
    }
    
    if (this.startedMonth) {
      queryParams['start_date'] = this.startedMonth.toISOString().split('T')[0];
    }

    if (this.endedMonth) {
      queryParams['end_date'] = this.endedMonth.toISOString().split('T')[0];
    }

    if (this.orderBy) {
      queryParams['orderBy'] = this.orderBy;
    }

    if (this.invertedOrder) {
      queryParams['invertedOrder'] = this.invertedOrder;
    }

    if (this.endDate >= this.startDate || !this.startDate && this.endDate || !this.endDate && this.startDate) {
      this.invalidTime = false;
      this.router.navigate(['/broadcasts'], { queryParams });
    } else {
      this.invalidTime = true;
    }
}

  confirmDelete(broadcast: LeadList): void {
    this.confirmationService
      .show(`Deseja realmente excluir o broadcast ${broadcast.name}?`, () => this.delete(broadcast.id));
  }

  confirmCancel(broadcast: LeadList) {
    this.confirmationService
      .show(`Deseja realmente cancelar o broadcast ${broadcast.name}?`, () => this.cancel(broadcast.id));
  }

  delete(id: string): void {
    this.subscriptions.push(
      this.broadcastsService.delete(id).subscribe(() => {
        this.toastService.success('Broadcast excluído com sucesso!');
        this.listPaginated();
      })
    );
  }

  cancel(id: string) {
    this.subscriptions.push(
      this.broadcastsService.cancel(id).subscribe(() => {
        this.toastService.success('Broadcast cancelado com sucesso!');
        this.listPaginated();
      })
    );
  }

  toggleStats() {
    this.statisticsClass = this.statisticsToggled ? 'hide' : 'appear';
    if (this.statisticsClass === 'hide') {
      setTimeout(() => {
        this.statisticsToggled = !this.statisticsToggled
      }, 210);
    }else{
      this.statisticsToggled = !this.statisticsToggled;
    }
  }

  changeStatsTotal() {
    this.statisticsTotal = 0;
    this.statisticsTotalClass = 'pulse';
    const increaseNumber = setInterval(() => {
      if (this.statisticsTotal >= this.statisticsEstimated) {
        this.statisticsTotal = this.statisticsEstimated;
        this.statisticsTotalClass = 'boop';
        return clearInterval(increaseNumber);
      }
      if (this.statisticsEstimated <= 100){
        this.statisticsTotal++;
      }
      else{
        this.statisticsTotal += Number.parseInt((this.statisticsEstimated / 15).toFixed());
      }
    }, 50)
  }

  getTotal() {
    if(this.statisticsStartDate && this.statisticsEndDate && this.statisticsStartDate > this.statisticsEndDate) {
      this.statisticsEstimated = 0;
      this.statisticsTotal = 0;
      return;
    }

    if (this.showBroadcastsStatistics) {
      this.listPaginated(this.statisticsStartDate ? this.statisticsStartDate.toISOString() : null, this.statisticsEndDate ? this.statisticsEndDate.toISOString() : null);
    }else {
      this.listPaginated();
    }

    this.broadcastsService.getTotalSent(this.statisticsStartDate ? this.statisticsStartDate.toISOString() : null, this.statisticsEndDate ? this.statisticsEndDate.toISOString() : null).subscribe((r) => {
      if(r.total) {
        this.statisticsEstimated = r.total
        this.changeStatsTotal()
      }else{
        this.statisticsEstimated = 0
        this.statisticsTotal = 0
      }
    });
  }

  orderByChanged() {
    this.listPaginated(), {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderBy: this.orderBy,
        startedMonth: this.startedMonth,
        endedMonth: this.endedMonth
      },
      queryParamsHandling: 'merge'
    }
  }
  
  invertOrder(): void {
    this.invertedOrder = !this.invertedOrder;
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        invertedOrder: this.invertedOrder,
        startedMonth: this.startedMonth,
        endedMonth: this.endedMonth
      },
      queryParamsHandling: 'merge'
    })
  }
  
  new(modal: any, broadcastId: string): void {
    this.broadcastForm.setValue({ broadcastId: broadcastId, providerAccountId: this.defaultProviderAccountId});
    this.modalService.open(modal, { size: 'md' });
  }

  acceptModal(modal: any, modalAccept): void {
    modal.dismiss('CLOSE');
    this.modalService.open(modalAccept, { size: 'md' });
  }

  resend(modal: any): void {
    const broadcast = this.broadcastForm.getRawValue();
    this.subscriptions.push(
      this.broadcastsService.resend(broadcast.broadcastId, broadcast.providerAccountId).subscribe(
        () => {
          this.toastService.success('Broadcast reprocessado com sucesso!');
          modal.close();
        },
        res => this.toastService.error(res?.error?.message || 'Erro ao reprocessar broadcast'))
    );
  }

}
